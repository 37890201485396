.terms-box {
  margin-top: -32px;

  h2 {
    margin-top: 32px;
  }
  p {
    margin-top: 16px;
    margin-bottom: 0px;
  }
  ul {
    margin-top: 20px;
    margin-bottom: 10px;
    padding-left: 40px;
    list-style-type: disc;
  }
  a {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}
