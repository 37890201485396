.pagination {
  font-family: Inter;
  display: flex;
  list-style-type: none;
  margin-bottom: 50px;
  margin-top: 50px;

  filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.1))
    drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.06));

  @media only screen and (max-width: 1000px) {
    display: block;
  }

  &__link {
    padding: 8px 16px;
  }

  &__button {
    padding: 8px;
    border: 1px solid #d1d5db;
    float: left;
    text-decoration: none;
    color: #4b5563;
    cursor: pointer;

    &:first-of-type {
      border-radius: 6px 0 0 6px;
      border-right: none;
    }

    &:last-of-type {
      border-radius: 0 6px 6px 0;
      border-left: none;
    }

    &-break {
      color: #4b5563;
    }

    &-active {
      display: none;
    }
  }
}
