/**
Based on the styles from
https://github.com/hypeserver/react-date-range/blob/da39bd1148b2ddc7aa3dc0e05ec9f44bbfd97bb6/src/theme/default.scss

All changes marked with a comment "Custom"
**/

.rdrCalendarWrapper {
  font-family: Inter;
}

.rdrDayToday .rdrDayNumber span {
  &:after {
    background: #07a549; /** green.500 **/
  }
}

.rdrMonth {
  width: auto;
}
