@import '../../styles/globals';

.order-page__modal-connected-list-item {
  font-size: 16px;
  line-height: 40px;
  list-style: none;
  cursor: pointer;
  padding-left: 16px;

  &:hover {
    color: $colour-white;
    background: $colour-darkgreen;
  }
}

.order-page__modal-connected-list {
  margin-top: 20px;
  height: 200px;
  background: $colour-offwhite;
  border: 1px solid $colour-modal-grey;
  border-radius: 4px;
  padding-left: 0;
  overflow: hidden;
  background: $colour-offwhite;
  overflow-y: scroll;
}
