/**
Based on the styles from
https://github.com/hypeserver/react-date-range/blob/da39bd1148b2ddc7aa3dc0e05ec9f44bbfd97bb6/src/theme/default.scss
**/

.rdrCalendarWrapper {
  font-family: Inter;
}

.rdrDayToday .rdrDayNumber span {
  &:after {
    background: #07a549; /** green.500 **/
  }
}

.rdrMonthAndYearWrapper {
  background-color: #e5e7eb;
  border-radius: 8px 8px 0 0;
  padding-top: 0;
}

.rdrMonth {
  width: auto;
}

// .rdrDay {
//   padding: 4px;

//   .rdrDayNumber {
//     background: #f3f4f6;
//     border-radius: 40px;

//     &:after {
//       content: '';
//       border: 1px solid #f3f4f6;
//       border-radius: 1.333em;
//       position: absolute;
//       top: -2px;
//       bottom: -2px;
//       left: 0px;
//       right: 0px;
//       background: transparent;
//     }
//   }
// }

.rdrNextPrevButton {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;

  &:hover {
    background-repeat: no-repeat;
    background-position: center;
    background-color: #eff2f7;
  }

  i {
    display: none;
  }
}

.rdrDayDisabled {
  background-color: transparent;
}

.rdrPprevButton {
  background-image: url("data:image/svg+xml,%3Csvg width='19' height='12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M18.75 6a.75.75 0 0 0-.75-.75H3.81l3.52-3.52A.75.75 0 1 0 6.27.67l-4.8 4.8L.94 6l.53.53 4.8 4.8a.75.75 0 0 0 1.06-1.06L3.81 6.75H18a.75.75 0 0 0 .75-.75Z' fill='%234B5563'/%3E%3C/svg%3E");

  &:hover {
    background-image: url("data:image/svg+xml,%3Csvg width='19' height='12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M18.75 6a.75.75 0 0 0-.75-.75H3.81l3.52-3.52A.75.75 0 1 0 6.27.67l-4.8 4.8L.94 6l.53.53 4.8 4.8a.75.75 0 0 0 1.06-1.06L3.81 6.75H18a.75.75 0 0 0 .75-.75Z' fill='%234B5563'/%3E%3C/svg%3E");
  }
}

.rdrNextButton {
  background-image: url("data:image/svg+xml,%3Csvg width='19' height='12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M.25 6A.75.75 0 0 1 1 5.25h14.19l-3.52-3.52A.75.75 0 0 1 12.73.67l4.8 4.8.53.53-.53.53-4.8 4.8a.75.75 0 1 1-1.06-1.06l3.52-3.52H1A.75.75 0 0 1 .25 6Z' fill='%234B5563'/%3E%3C/svg%3E");

  &:hover {
    background-image: url("data:image/svg+xml,%3Csvg width='19' height='12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M.25 6A.75.75 0 0 1 1 5.25h14.19l-3.52-3.52A.75.75 0 0 1 12.73.67l4.8 4.8.53.53-.53.53-4.8 4.8a.75.75 0 1 1-1.06-1.06l3.52-3.52H1A.75.75 0 0 1 .25 6Z' fill='%234B5563'/%3E%3C/svg%3E");
  }
}
