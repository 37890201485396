/****** Font Sizes ******/

$fontsize-big-header: 38px;
$fontsize-header: 24px;
$fontsize-subheader: 18px;

$fontsize-regular: 16px;

$fontsize-small: 14px;
$fontsize-smallest: 12px;

/****** Colours ******/

$colour-green: #08b254;
$colour-faintgreen: #dce8dc;
$color-lightgreen: #89dbae;
$colour-mintgreen: #5cb85c;
$colour-darkmintgreen: #45ae45;
$colour-darkgreen: #019c46;
$colour-pastelgreen: #cef9d8;
$colour-orange: #f0ad4e;
$colour-lightorange: #fbcb88;
$colour-brightorange: #ffb9b9;
$colour-white: #ffffff;
$colour-offwhite: #f4f4f4;
$colour-offwhiteSecond: #fbfbfb;
$colour-darkwhite: #eff8ef;
$colour-lightergrey: #eeeeee;
$colour-lightgrey: #e5e5e5;
$colour-medgrey: #cccccc;
$colour-grey: #d8d8d8;
$colour-table-grey: #a9a9a9;
$colour-light-bluegrey: #e5e7eb;
$colour-grayishblue: #4b5563;
$colour-yellow: #fef5a7;

$colour-darkgrey: #555555;
$colour-darkergrey: #313131;
$colour-black: #000000;
$colour-lightblack: #373a3c;
$colour-dark-blueblack: #111827;
$colour-lightred: #fff4f4;
$colour-red: #ffaba7;
$colour-black-overlay: rgba(0, 0, 0, 0.7);
$colour-blue: #0275d8;
$colour-darkerblue: #004fb9;
$colour-skyblue: #1ab4d7;
$colour-brightblue: #a6e9ff;
$colour-red: #d9534f;
$colour-darkred: #a94442;
$colour-darkred: #d1d5db;

// Card
$colour-card-lightgrey: #ebebeb;

$colour-table-grey: #c4c4c4;
$colour-modal-grey: #bfbfbf;
