body {
  overflow: hidden;
  background-color: #f4f4f4; // This is from the legacy app. If removing this, we would need to apply manually to the parts we want the background on.
}

/** Settings page **/
.w-container {
  max-width: 1440px;
  margin-right: auto;
  margin-left: auto;
}

.w-container-width {
  max-width: 1440px;
}
